<template>
    <div class="rightLink">
        <div class="bottomDiv1" style="width: 97%; height: auto">
          <div class="title" style="border-bottom: none">故障统计</div>
          <!-- <div @click="isshow" style="float: right; margin-right: 120px;" >已处理列表</div> -->
          <div class="tableDiv">
            <el-table
              ref="multipleTableRef"
              :data="list3s"
              border 
              v-loading="loading"
              height="650px"
              style="
                width: 90%;
                margin: 20px auto;
                overflow-y: auto;
              "
            >
              <!-- <el-table-column label="id" prop="" /> -->
              <el-table-column label="故障编号" prop="station_name" />    
              <el-table-column label="运营商" prop="username" />
              <el-table-column label="故障对象" prop="username" />
              <el-table-column label="充电站" prop="station_name" />    
              <el-table-column label="设备编号" prop="device_id" />
              <el-table-column label="故障类型" prop="status" />
              <el-table-column label="详细情况" prop="details" />
              <el-table-column label="申报时间" prop="create_time" width="160" />
              <el-table-column label="处理时间" prop="create_time" width="160"  />
              <el-table-column label="处理人" prop="create_time" />
              <!-- <el-table-column
                label="操作"
                width="90"
                align="center"
              >
                <template #default="scope">
                  <div
                    class="BtnSearch1 BtnSearch"
                    @click="detail(scope.$index, scope.row)"
                    >处理</div
                  >
                </template>
              </el-table-column> -->
            </el-table>
          
          </div>
        </div>
      </div>

    </template>
    
    <style scoped >
    /* 标题文字样式 */
    .index_title {
      font-size: 18px;
      color: #333;
      border-left: 5px solid #04a0c3;
      padding-left: 25px;
    }
    .bottomDiv1{
      background-color: #fff;
      padding: 20px;
    }
    .UserHome {
      padding: 2px 30px 2px 2px;
    }
    
    .card{
      padding: 2% ;
      
      }
      .card > p{
          margin-top: 5px;
      
      }
   
    </style>
    
    <script>
    // import { onMounted, reactive, toRefs, onBeforeUnmount, ref } from "vue-demi";
    import { onMounted, reactive, toRefs, ref } from "vue-demi";
    import { fault_message } from "@/request/api";
    // import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
    export default {
      name: "News",
      // components: {
      //   Vue3SeamlessScroll,
      // },
      setup() {
        const data = reactive({
          list: [
          
            // }
          ],
          list2: [
            
          ],
          list3:[],
          list3s:[],
          //   弹框
          Dialog: {
            editLevy: false,
          },
        });
        
        
        const getList = () => {
        
          const dataa = {
            operator_id :sessionStorage.getItem("userID")
          }
            fault_message(dataa).then((res) => {
              if (res) {
                data.loading = false;
                if (res.code == 200) {
                  res.data.forEach((a)=>{
                  console.log(a,'aaaaaa');
                  if(a.work_type == 0){
                      data.list3.push(a)
  
                    } else if(a.work_type == 1){
                      data.list3s.push(a)
                    }
                    
              })
                } else {
                  ElMessage.error(res.msg);
                }
              } else {
                ElMessage.error("数据获取失败");
              }
            });
            
        };
        
        onMounted(() => {
          getList()
        });
        
        return {
          ...toRefs(data),
          getList
        };
      },
    };
    </script>
    